<template>
  <Content>
    <template #contentTitle>
      {{ $t("finance.account_balance") }}
    </template>
    <template #contentBody>
      <a-space direction="vertical" :size="30" style="width: 100%">
        <a-table
          :columns="summaryColumns"
          :data-source="summaryTableData.tableList"
          :scroll="{ x: 500 }"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :loading="summaryTableData.loading"
          size="small"
        >
          <template #balanceTitle>
            <a-space :size="30">
              <span>{{ $t("finance.account_balance") }}</span>
              <router-link to="/finance/rechargerecord">
                <a-button type="primary">{{
                  $t("logistics.immediately_top_up")
                }}</a-button>
              </router-link>
            </a-space>
          </template>
          <template #amount="{ record }">
            <a-row type="flex">
              <a-col style="width: 70px"
                ><span
                  >{{ record.currencyCode }} {{ record.currencySymbol }}</span
                ></a-col
              >
              <a-col flex="2">
                <span v-if="record.amount < 0" class="text-error">{{
                  $filters.amountToFixed2(record.amount)
                }}</span>
                <span v-else class="text-success">{{
                  $filters.amountToFixed2(record.amount)
                }}</span>
              </a-col>
            </a-row>
          </template>
          <template #overdraftFacility="{ record }">
            <a-row type="flex">
              <a-col style="width: 70px"
                ><span
                  >{{ record.currencyCode }} {{ record.currencySymbol }}</span
                ></a-col
              >
              <a-col style="width: 150px"
                ><span class="text-success">{{
                  $filters.amountToFixed2(record.overdraftFacility)
                }}</span></a-col
              >
              <!-- <a-col flex="1"><a-button type="primary" size="small">{{ $t('finance.increase_limit') }}</a-button></a-col> -->
            </a-row>
          </template>
           <template #freeze="{ record }">
            <a-row type="flex">
              <a-col style="width: 70px"
                ><span
                  >{{ record.currencyCode }} {{ record.currencySymbol }}</span
                ></a-col
              >
              <a-col flex="2">
                <span v-if="record.freezeOverdraftFacility > 0" class="text-error">{{
                  $filters.amountToFixed2(record.freezeOverdraftFacility)
                }}</span>
                <span v-else class="text-success">{{
                  $filters.amountToFixed2(record.freezeOverdraftFacility)
                }}</span>
              </a-col>
            </a-row>
          </template>
          <template #overdraftDueDateTime="{ record }">
            <span>{{
              $filters.utcToCurrentTime(record.overdraftDueDateTime)
            }}</span>
          </template>
          <template #overdraftStatus="{ record }">
            <span
              v-if="record.overdraftStatus === overdraftStatusEnum.normal"
              class="text-success"
              >{{ $t($enumLangkey('overdraftStatus', record.overdraftStatus)) }}</span
            >
            <span
              v-else-if="
                record.overdraftStatus === overdraftStatusEnum.overduePayment
              "
              class="text-error"
            >
              {{ $t($enumLangkey('overdraftStatus', record.overdraftStatus)) }}
            </span>
            <span v-if="record.lastMonthOwingMoney < 0" class="text-error">
              ({{ $t("finance.money") }}: {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.lastMonthOwingMoney * -1) }})
            </span>
          </template>
        </a-table>
        <div>
          <a-button type="primary">
            <router-link to="/finance/inventory">
              {{ $t("finance.balance_transaction_details") }}
            </router-link>
          </a-button>
          <a-tabs
            v-model:activeKey="tableData.balanceId"
            @change="searchLogistics"
          >
            <a-tab-pane
              v-for="item in summaryTableData.tableList"
              :key="item.id"
              :tab="getLanguageName(item.currencyCnName, item.currencyEnName)"
            ></a-tab-pane>
          </a-tabs>
          <a-row :gutter="[32, 16]">
            <a-col>
              <a-select
                style="min-width: 150px"
                v-model:value="searchTabState.transactionType"
                :placeholder="$t('finance.transaction_type')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in transactionTypeList"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('transactionType', item))"
                  >{{ $t($enumLangkey("transactionType", item)) }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="min-width: 150px"
                v-model:value="searchTabState.costType"
                :placeholder="$t('finance.business_type')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in businessTypeList"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('businessType', item))"
                  >{{ $t($enumLangkey("businessType", item)) }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker
                style="min-width: 150px"
                allowClear
                @change="handleDateChange"
              />
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
                >{{ $t("common.query") }}</a-button
              >
            </a-col>
          </a-row>
          <!-- 表格 -->
          <a-table
            class="mt-3"
            :columns="columns"
            :data-source="tableData.tableList"
            :scroll="{ x: 500 }"
            :pagination="false"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :loading="tableData.loading"
            size="small"
          >
            <template #creationTime="{ record }">
              <span>
                {{ $filters.utcToCurrentTime(record.creationTime) }}
              </span>
            </template>
            <template #transactionType="{ record }">
              <span>
                {{ $t($enumLangkey('transactionType', record.transactionType)) }}
              </span>
            </template>
            <template #costType="{ record }">
              <span>{{ $t($enumLangkey('businessType', record.costType)) }}</span>
            </template>

             <template #businessNo="{ record }">
            <CPlanNumberItem  :no="record.businessNo"></CPlanNumberItem>
            </template>

            <template #countryName="{ record }">
              <span>
                {{
                  getLanguageName(record.countryCnName, record.countryEnName)
                }}
              </span>
            </template>
            <template #businessHappenCountry>
              <span> {{ $t("warehouse.business_happen_country") }} </span>
            </template>
            <template #money="{ record }">
              <span v-if="record.changeAmount < 0" class="text-error">
                {{
                  $filters.formatCurrencyAmount(
                    record.currencySymbol,
                    record.changeAmount
                  )
                }}
              </span>
              <span v-else-if="record.changeAmount > 0" class="text-success">
                {{
                  $filters.formatCurrencyAmount(
                    record.currencySymbol,
                    record.changeAmount
                  )
                }}
              </span>
              <span v-else>
                {{
                  $filters.formatCurrencyAmount(
                    record.currencySymbol,
                    record.changeAmount
                  )
                }}
              </span>
            </template>
            <template #balance="{ record }">
              <span>{{
                $filters.formatCurrencyAmount(
                  record.currencySymbol,
                  record.afterBalance,
                  false
                )
              }}</span>
            </template>

            <template #footer>
               <c-pager
                class="text-center"
                @handlePage="handlePage"
                :page-data="tableData.pageData"
              />
            </template>
          </a-table>
        </div>
      </a-space>
    </template>
  
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, toRefs } from "vue";
import { Table, Button, Row, Col, Tabs, Space, Select, DatePicker } from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import {
  getBalanceList,
  getBalanceListPaged,
} from "../../api/modules/finance/balance.js";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import {
  overdraftStatus as overdraftStatusEnum,
  transactionType as transactionTypeEnum,
  businessType as businessTypeEnum,
} from "@/enum/enum.json";
import { currentTimeToUtc } from '@/utils/general';
import CPlanNumberItem from "../components/CPlanNumberItem.vue";

export default defineComponent({
  name: "finance_account_balance",
  components: {
    ATable: Table,CPlanNumberItem,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    CPager,
    Content,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const summaryColumns = [
      {
        width: 200,
        fixed: "left",
        slots: {
          title: "balanceTitle",
          customRender: "amount",
        },
      },
      {
        title: () => vueI18n.t("finance.overdraft_limit"),
        width: 200,
        slots: {
          customRender: "overdraftFacility",
        },
      },
        {
        title: () => vueI18n.t("finance.freeze"),
        width: 200,
        slots: {
          customRender: "freeze",
        },
      },
      {
        title: () => vueI18n.t("finance.expiration_time"),
        width: 150,
        slots: {
          customRender: "overdraftDueDateTime",
        },
      },
      {
        title: () => vueI18n.t("finance.overdraft_status"),
        width: 200,
        slots: {
          customRender: "overdraftStatus",
        },
      },
    ];

    const columns = [
      {
        title: () => vueI18n.t("warehouse.time"),
        width: 150,
        fixed: "left",
        slots: {
          customRender: "creationTime",
        },
      },
      {
        title: () => vueI18n.t("finance.transaction_serial_number"),
        dataIndex: "balanceLogNo",
        width: 200,
      },
      {
        title: () => vueI18n.t("finance.transaction_type"),
        width: 100,
        slots: {
          customRender: "transactionType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.business_type"),
        width: 150,
        slots: {
          customRender: "costType",
        },
      },
      {
        title: () => vueI18n.t("finance.relevant_business_number"),
        dataIndex: "businessNo",
        width: 150,
         slots: {
          customRender: "businessNo",
        },
      },
      {
        title: () => vueI18n.t("warehouse.business_happen_country"),
        width: 100,
        slots: {
          customRender: "countryName",
        },
      },
      {
        title: () => vueI18n.t("finance.money"),
        width: 100,
        slots: {
          customRender: "money",
        },
      },
      {
        title: () => vueI18n.t("finance.balance"),
        width: 100,
        slots: {
          customRender: "balance",
        },
      },
    ];

    const state = reactive({
      loading: true,
      summaryTableData: {
        tableList: [],
        loading: true,
      },
      searchTabState: {
        transactionType: null,
        costType: null,
        startTime: null,
        endTime: null,
      },
      searchTabStateCache: {},
      tableData: {
        tableList: [],
        loading: true,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
        balanceId: "",
      },
    });

    const transactionTypeList = Object.values(transactionTypeEnum);
    const businessTypeList = Object.values(businessTypeEnum);

    const handleDateChange = (e) => {
      let startTime = null;
      let endTime = null;
      if (Array.isArray(e) && e.length > 1) {
        startTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchTabState.startTime = startTime;
      state.searchTabState.endTime = endTime;
    };

    const getTableList = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.tableData.pageData, state.searchTabStateCache, {
        balanceId: state.tableData.balanceId,
      });
      getBalanceListPaged(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getTableList();
    };

    const searchLogistics = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getTableList();
    };

    const handleSearch = () => {
      Object.assign(state.searchTabStateCache, state.searchTabState);
      searchLogistics();
    };

    const getLanguageName = (cnName, enName) => {
      if (getters.language === "zh-CN") {
        return cnName || '-';
      } else {
        return enName || '-';
      }
    };

    const getChangeAmount = (record) => {
      if (record.changeAmount < 0) {
        let amount = Math.abs(record.changeAmount);
        return `-${record.currencySymbol}${amount}`;
      } else if (record.changeAmount > 0) {
        return `+${record.currencySymbol}${record.changeAmount}`;
      } else {
        return `${record.currencySymbol}${record.changeAmount}`;
      }
    };

    const getPageData = async () => {
      try {
        state.loading = true;
        let { result: summaryList } = await getBalanceList();
        state.summaryTableData.tableList = summaryList ?? [];
        state.summaryTableData.loading = false;
        if (summaryList.length) {
          state.tableData.balanceId = summaryList[0].id;
          searchLogistics();
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    onActivated(getPageData);

    return {
      ...toRefs(state),
      transactionTypeList,
      businessTypeList,
      summaryColumns,
      columns,
      overdraftStatusEnum,
      getPageData,
      handlePage,
      searchLogistics,
      getLanguageName,
      getChangeAmount,
      handleSearch,
      handleDateChange,
    };
  },
});
</script>
