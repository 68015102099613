import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 账户/余额 
  getBalanceList: "/api/ProductService/Balance/GetList",

  // 余额变更明细列表
  getBalanceListPaged: "api/ProductService/BalanceLog/GetListPaged",
  
  // 充值记录列表
  getRechargeList: "/api/ProductService/ReChargeLog/GetListPaged",
  // 获取计算汇率
  getExchangeRate: "/api/ProductService/ExchangeRate/GetExchangeRate",
  // 查询收款账户信息
  getPayeeAccountList: "/api/ProductService/PayeeAccount/GetListPaged",
  // 计算充值应付款
  getReChargePayMoney: "/api/ProductService/ReChargeLog/GetReChargePayMoney",
  // 创建充值
  createReChargeLog: "/api/ProductService/ReChargeLog/Create",
  // 提交充值
  submitReChargeLog: "/api/ProductService/ReChargeLog/Submit",
  // 获取充值详细
  getReChargeLog: "/api/ProductService/ReChargeLog/Get",
  // 取消充值
  cancelReChargeLog: "/api/ProductService/ReChargeLog/Cancel",
  // 打印充值
  printReChargeLog: "/api/ProductService/RechargeLog/GetBllPdf/rechargebill.pdf",
};

export const getList = ({
  businessNo,
  transactionType,
  businessType,
  countryId,
  facilityCode,
  currencyId,
  startTime,
  endTime,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      businessNo,
      transactionType,
      businessType,
      countryId,
      facilityCode,
      currencyId,
      startTime,
      endTime,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "POST",
  });
};

export const getDetail = (id) => {
  return client.request({
    url: Api.getDetail,
    params: { id },
    method: "get",
  });
};

export const getBalanceList = (data = {}) => {
  return client.request({
    url: Api.getBalanceList,
    data,
    method: "POST",
  });
};

export const getBalanceListPaged = (data = {}) => {
  return client.request({
    url: Api.getBalanceListPaged,
    data,
    method: "POST",
  });
};

export const getRechargeList = (data = {}) => {
  return client.request({
    url: Api.getRechargeList,
    data,
    method: "POST",
  });
};

export const getExchangeRate = (data = {}) => {
  return client.request({
    url: Api.getExchangeRate,
    data,
    method: "POST",
  });
};

export const getPayeeAccountList = (data = {}) => {
  return client.request({
    url: Api.getPayeeAccountList,
    data,
    method: "POST",
  });
};

export const getReChargePayMoney = (data = {}) => {
  return client.request({
    url: Api.getReChargePayMoney,
    data,
    method: "POST",
  });
};

export const createReChargeLog = (data = {}) => {
  return client.request({
    url: Api.createReChargeLog,
    data,
    method: "POST",
  });
};

export const submitReChargeLog = (data = {}) => {
  return client.request({
    url: Api.submitReChargeLog,
    data,
    method: "POST",
  });
};

export const getReChargeLog = (params = {}) => {
  return client.request({
    url: Api.getReChargeLog,
    params,
    method: "GET",
  });
};

export const cancelReChargeLog = (data = {}) => {
  return client.request({
    url: Api.cancelReChargeLog,
    data,
    method: "POST",
  });
};

export const printReChargeLog = (params = {}) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printReChargeLog);
  url = urlHelper.addQueryString(url, "id", params.id);
  url = urlHelper.addQueryString(url, "culture", params.culture);
  return url
};


